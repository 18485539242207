import React from 'react'
import MonumentCard from '../../components/MonumentCard/MonumentCard'
import { useStaticQuery, graphql } from 'gatsby'

const MonumentCardSiennicaCmentarzPrzyKoscieleKatakumby = () => {
  const { file } = useStaticQuery(
    graphql`
      {
        file(
          name: { regex: "/okladka/" }
          relativePath: {
            regex: "/siennica/cmentarz-przy-kosciele-i-katakumby/"
          }
        ) {
          childImageSharp {
            fluid(maxHeight: 350) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    `
  )

  return (
    <MonumentCard
      fluid={file.childImageSharp.fluid}
      linkTo={'/gminy/siennica/cmentarz-przy-kosciele-i-katakumby'}
      title={'Cmentarz przy kościele i katakumby'}
    >
      Osiemset lat temu pojawiła się pierwsza wzmianka dotycząca istnienia osady
      Siennica. W 1526 roku miejscowość ta otrzymała prawa miejskie. Utraciła je
      w 1869 roku w wyniku represji zrealizowanych na ziemiach polskich przez
      władze rosyjskie po stłumieniu powstania styczniowego. Do najważniejszych
      obiektów zabytkowych, które można obejrzeć na jej obszarze należy zaliczyć
      figurę Matki Boskiej Niepokalanej z pierwszej połowy dziewiętnastego
      wieku, niektóre nagrobki znajdujące się na cmentarzu parafialnym, budynek
      dawnego seminarium nauczycielskiego, klasztor poreformacki i kościół
      parafialny. Przy ostatnim z wymienionych obiektów znajdują się, budzące
      duże zaciekawienie, katakumby (krypty).
    </MonumentCard>
  )
}

export default MonumentCardSiennicaCmentarzPrzyKoscieleKatakumby
