import React from 'react'
import ArchiveCardWrapper from '../../../components/ArchiveCardWrapper/ArchiveCardWrapper'
import ArchiveWrapper from '../../../components/ArchiveWrapper/ArchiveWrapper'
import SEO from '../../../components/seo'
import { Header1 } from '../../../components/Headers/Headers'
import MonumentCardSiennicaCmentarzParafialny from '../../../monuments/siennica/MonumentCardSiennicaCmentarzParafialny'
import MonumentCardSiennicaCmentarzPrzyKoscieleKatakumby from '../../../monuments/siennica/MonumentCardSiennicaCmentarzPrzyKoscieleKatakumby'
import MonumentCardSiennicaMuzeumSzkolne from '../../../monuments/siennica/MonumentCardSiennicaMuzeumSzkolne'
import MonumentCardSiennicaPominkLasomin from '../../../monuments/siennica/MonumentCardSiennicaPomnikLasomin'
import MonumentCardRocznik from '../../../components/MonumentCard/MonumentCardRocznik'
import MonumentCardAsset from '../../../components/MonumentCard/MonumentCardAsset'

const SiennicaArchive = () => {
  return (
    <ArchiveWrapper>
      <SEO title="Materiały dotyczące gminy Siennica" />
      <ArchiveCardWrapper>
        <Header1>Materiały dotyczące gminy Siennica</Header1>
        <MonumentCardSiennicaCmentarzParafialny />
        <MonumentCardSiennicaCmentarzPrzyKoscieleKatakumby />
        <MonumentCardSiennicaMuzeumSzkolne />
        <MonumentCardSiennicaPominkLasomin />
        <MonumentCardRocznik
          title={'Siennica – Mińsk Mazowiecki, listopad 2018 r.'}
          author={'Stanisław Czajka'}
          linkTo={
            'https://edukacjaziemiaminska.s3.eu-west-1.amazonaws.com/rocznik/SiennicaRoczniktom262018StanislawCzajkaSiennica%E2%80%93MinskMazowieckilistopad2018r..pdf'
          }
        />
        <MonumentCardRocznik
          title={'Stanisław Benedykt Dłużewski i jego zacni antenaci'}
          author={'Waldemar Piekarski'}
          linkTo={
            'https://edukacjaziemiaminska.s3.eu-west-1.amazonaws.com/rocznik/MinskMazowieckiRoczniktom262018AndzelikaKucMonikaSzczepaniukKarolinaGankoKonradCzerskiArturMilewski.pdf'
          }
        />
        <MonumentCardAsset
          title={'SIENNICA Gmina i miejscowość 100 lat temu i dziś'}
          assetName={'MATERIAŁ'}
          pdfIcon
          linkTo={
            'https://edukacjaziemiaminska.s3.eu-west-1.amazonaws.com/siennica/Siennica+Dawniej+i+Dzisiaj.pdf'
          }
          buttonText={'Otwórz'}
        />
        <MonumentCardAsset
          title={'Sienniczanie w Bitwie Warszawskiej 1920 roku'}
          assetName={'MATERIAŁ'}
          pdfIcon
          linkTo={
            'https://edukacjaziemiaminska.s3.eu-west-1.amazonaws.com/siennica/wojna-1920-siennica-1600672820.pdf'
          }
          buttonText={'Otwórz'}
        />
        <MonumentCardAsset
          title={
            'Gmina Siennica w okresie wojny polsko-bolszewickiej w 1920 roku'
          }
          assetName={'MATERIAŁ'}
          pdfIcon
          linkTo={
            'https://edukacjaziemiaminska.s3.eu-west-1.amazonaws.com/siennica/wojna-1920-siennica-zugaj-1598253439.pdf'
          }
          buttonText={'Otwórz'}
        />
        <MonumentCardAsset
          title={'Klasztor zakonu OO. Franiciszkanów-Reformatów w Siennicy'}
          assetName={'MATERIAŁ'}
          pdfIcon
          linkTo={
            'https://edukacjaziemiaminska.s3.eu-west-1.amazonaws.com/siennica/zakon-franciszkanow-1545993780.pdf'
          }
          buttonText={'Otwórz'}
        />
        <MonumentCardAsset
          title={'Siennica i seminarium  (1927)'}
          author={'P. Szeląg'}
          assetName={'AUDIOBOOK'}
          audioIcon
          audioURL={
            'https://edukacjaziemiaminska.s3.eu-west-1.amazonaws.com/audiobooki/P.+Szela%CC%A8g+Siennica+i+seminarium++(1927).mp3'
          }
        />
      </ArchiveCardWrapper>
    </ArchiveWrapper>
  )
}

export default SiennicaArchive
