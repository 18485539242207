import React from 'react'
import MonumentCard from '../../components/MonumentCard/MonumentCard'
import { useStaticQuery, graphql } from 'gatsby'

const MonumentCardSiennicaMuzeumSzkolne = () => {
  const { file } = useStaticQuery(
    graphql`
      {
        file(
          name: { regex: "/okladka/" }
          relativePath: { regex: "/siennica/muzeum-szkolne/" }
        ) {
          childImageSharp {
            fluid(maxHeight: 350) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    `
  )

  return (
    <MonumentCard
      fluid={file.childImageSharp.fluid}
      linkTo={'/gminy/siennica/muzeum-szkolne-w-siennicy'}
      title={'Muzeum Szkolne w Siennicy'}
    >
      Osiemset lat liczy pierwsza wzmianka dotycząca Siennicy. Ważnym momentem
      historycznym w dziejach miejscowości było nadanie jej praw miejskich w
      1526 roku. Ich utrata nastąpiła natomiast w 1869 roku. Obecnie jest ona
      siedzibą gminy, na obszarze której znajdują się liczne obiekty zabytkowe.
      Zaliczyć do nich można m.in. zespoły dworsko – parkowe w Bożej Woli,
      Dłużewie, Grzebowilku, Kątach, Pogorzeli i Żakowie. Zabytkowy młyn wodny
      stanowi atrakcję dla osób odwiedzających Ptaki. W Siennicy uwagę turystów
      zwracają przede wszystkim kościół i klasztor poreformacki.
    </MonumentCard>
  )
}

export default MonumentCardSiennicaMuzeumSzkolne
