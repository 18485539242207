import React from 'react'
import MonumentCard from '../../components/MonumentCard/MonumentCard'
import { useStaticQuery, graphql } from 'gatsby'

const MonumentCardSiennicaCmentarzParafialny = () => {
  const { file } = useStaticQuery(
    graphql`
      {
        file(
          name: { regex: "/okladka/" }
          relativePath: { regex: "/siennica/cmentarz-parafialny/" }
        ) {
          childImageSharp {
            fluid(maxHeight: 350) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    `
  )

  return (
    <MonumentCard
      fluid={file.childImageSharp.fluid}
      linkTo={'/gminy/siennica/cmentarz-parafialny-w-siennicy'}
      title={'Cmentarz Parafialny w Siennicy'}
    >
      Siennica w swoich losach wiele lata było kluczowym miastem na mapie
      mazowsze, a mimo powolnego wchodzenia w cień kolei terespolskiej i
      rosnącego znaczenia Mińska Mazowieckiego. Jednym z dowodów świetności tego
      ośrodka jest cmentarz grzebalny.
    </MonumentCard>
  )
}

export default MonumentCardSiennicaCmentarzParafialny
