import React from 'react'
import MonumentCard from '../../components/MonumentCard/MonumentCard'
import { useStaticQuery, graphql } from 'gatsby'

const MonumentCardSiennicaPominkLasomin = () => {
  const { file } = useStaticQuery(
    graphql`
      {
        file(
          name: { regex: "/okladka/" }
          relativePath: { regex: "/siennica/pomnik-lasomin/" }
        ) {
          childImageSharp {
            fluid(maxHeight: 350) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    `
  )

  return (
    <MonumentCard
      fluid={file.childImageSharp.fluid}
      linkTo={'/gminy/siennica/pomnik-w-lasominie'}
      title={'Pomnik w Lasominie'}
    >
      Pomnik znajduje się na południowym krańcu wsi. na rozwidleniu dróg
      prowadzących do Kośmin i Nowogrodu. Poświęcony jest pamięci rodaków
      poległych w obronie ojczyzny w 1920 r. Pomnik ufundowało w 1932 r. Koło
      Młodzieży Wiejskiej „Wici” w Lasominie działające wówczas w tejże
      miejscowości. Treść: Rodakom naszym poległym w obronie Ojczyzny w r. 1820
      kamień ten na pamiątkę położyło Koło Mł. Wiejskiej „ Wici”. Do pomnika
      dodana 11 listopada 2011 roku przez samorząd gminy Siennica tablica
      upamiętniająca mieszkańców którzy zginęli w wojennych latach 20 i 40.
    </MonumentCard>
  )
}

export default MonumentCardSiennicaPominkLasomin
